const TTM_API_KEY = process.env.REACT_APP_TTM_API_KEY;
const TTM_API_URL = 'https://api.openai.com/v1/chat/completions';
export async function analyzeAnswers(answers) {
    const prompt = `Basé sur les réponses suivantes à un questionnaire sur les préférences professionnelles, suggérez 6 métiers qui correspondent le mieux aux réponses données. Donnez uniquement les titres des métiers.

Réponses au questionnaire :
Donne un emojis qui correspond au métier;
${Object.entries(answers).map(([q, a]) => `Q${parseInt(q) + 1}: ${a}`).join('\n')}`;

    try {
        const response = await fetch(TTM_API_URL, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${TTM_API_KEY}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                model: "gpt-4o-mini",
                messages: [{ role: "user", content: prompt }],
                temperature: 0.7,
                max_tokens: 150
            })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        const suggestions = data.choices[0].message.content;
        return suggestions.split('\n').map(job => job.replace(/^\d+\.\s*/, '').trim());
    } catch (error) {
        console.error('Erreur lors de lappel à lAPI TTM:', error);
        return [];
    }
}

export async function getJobExplanation(jobTitle, answers) {
    const prompt = `Basé sur les réponses suivantes à un questionnaire sur les préférences professionnelles, fournissez des informations structurées pour le métier de "${jobTitle}":

1. Formation : Indiquez le niveau d'étude (intitulé de la formation) nécessaire pour accéder à ce métier en France (recherchez sur le site : onisep.fr, pour confirmez les informations).
2. Compétences requises : Listez 3 compétences nécessaires pour accéder à ce métier en France (recherchez sur le site : onisep.fr, pour confirmez les informations).
3. Pourquoi : Expliquez en 3 phrases pourquoi ce métier correspond le mieux aux réponses données lors du quiz.
4. Salaire moyen : Indiquez le salaire moyen brut par an de ce métier en France (recherchez sur le site : onisep.fr, pour confirmez les informations).

Réponses au questionnaire :
${Object.entries(answers).map(([q, a]) => `Q${parseInt(q) + 1}: ${a}`).join('\n')}

Veuillez fournir les informations dans le format suivant :

Formation : [niveau d'étude]
Compétences : [compétence 1], [compétence 2], [compétence 3]
Pourquoi ? : [explication en 3 phrases]
Salaire moyen : [salaire moyen en euros]`;

    try {
        const response = await fetch(TTM_API_URL, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${TTM_API_KEY}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                model: "gpt-4o-mini",
                messages: [{ role: "user", content: prompt }],
                temperature: 0.7,
                max_tokens: 250
            })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data.choices[0].message.content.trim();
    } catch (error) {
        console.error('Erreur lors de lappel à lAPI TTM pour lexplication:', error);
        return "Une erreur est survenue lors de la génération de l'explication.";
    }
}