import React from 'react';

function PrivacyPage({ onBack }) {
  return (
    <div className="page active">
      <h2 className="legalt">Politique de confidentialité</h2>
      <section className="legalp">
      <div class="header">
        <p>Dernière mise à jour : 19/09/2024</p>
    </div>

    <section>
        <h2>Notre Engagement</h2>
        <div class="highlight">
            <p>Notre quiz d'orientation professionnelle est gratuit et ne nécessite aucune création de compte. 
              Nous nous engageons à respecter votre vie privée.</p>
        </div>
    </section>

    <section>
        <h2>Fonctionnement du Service</h2>
        <p>Notre service fonctionne de manière simple :</p>
        <ul>
            <li>Vous répondez à une série de questions sur vos préférences professionnelles</li>
            <li>Notre système d'IA analyse vos réponses de manière anonyme</li>
            <li>Vous recevez instantanément des recommandations personnalisées</li>
        </ul>
    </section>

    <section>
        <h2>Données Collectées</h2>
        <p>Nous ne collectons :</p>
        <ul>
            <li>Aucune donnée personnelle (nom, email, etc.)</li>
            <li>Aucune information d'identification</li>
            <li>Uniquement des données techniques anonymes nécessaires au fonctionnement du site</li>
        </ul>
    </section>

    <section>
        <h2>Utilisation de l'Intelligence Artificielle</h2>
        <p>Notre IA :</p>
        <ul>
            <li>Traite vos réponses de manière instantanée et anonyme</li>
            <li>Ne stocke aucune donnée après la génération des recommandations</li>
            <li>Est utilisée uniquement pour fournir des suggestions pertinentes</li>
        </ul>
    </section>

    <section>
        <h2>Cookies et Données Techniques</h2>
        <p>Notre site utilise uniquement :</p>
        <ul>
            <li>Les cookies techniques essentiels au fonctionnement du site</li>
            <li>Des données de navigation anonymes pour l'amélioration du service</li>
        </ul>
    </section>

    <section>
        <h2>Hébergement</h2>
        <p>Le site est hébergé par Vercel Inc., conformément aux standards de sécurité en vigueur.</p>
        <p>privacy@vercel.com</p>
    </section>

    <section>
        <h2>Contact</h2>
        <p>Pour toute question concernant notre politique de confidentialité :</p>
        <p>contact.ttmweb@gmail.com</p>
    </section>
      </section>
      <br />
      <button className="btn return" onClick={onBack}>Retour</button>
    </div>
  );
}

export default PrivacyPage;