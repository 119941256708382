import React from 'react';
import { Helmet } from 'react-helmet-async';

function ContactPage({ onBack }) {
  return (
    <>
      <Helmet>
        <title>Contact - Trouve Ton Métier</title>
        <meta 
          name="description" 
          content="Contactez-nous pour toute question sur notre quiz d'orientation professionnelle."
        />
        <link 
          rel="canonical" 
          href="https://trouvetonmetier.fr/contact" 
        />
      </Helmet>

      <div className="page active contactpage">
        <h1 className="legalt">
          Contact
        </h1>

        <div className="contact-content">
          <p>
            Vous avez des questions, des suggestions ou simplement envie d'en savoir plus 
            sur notre quiz d'orientation professionnelle ? 
            <br />
            N'hésitez pas à nous contacter !
          </p>
          
          <p>
            <a 
              href="mailto:contact.ttmweb@gmail.com"
              aria-label="Nous contacter par email"
            >
              contact.ttmweb@gmail.com
            </a>
          </p>
        </div>

        <button 
          className="btn return"
          onClick={onBack}
          aria-label="Retour à la page d'accueil"
        >
          Retour
        </button>
      </div>
    </>
  );
}

export default ContactPage;