import React from 'react';

function CGUPage({ onBack }) {
  return (
    <div className="page active">
      <h2 className="legalt">Conditions Générales d'Utilisation</h2>
      <section className="legalp">
        <div className="header">
          <p>Dernière mise à jour : 19/09/2024</p>
        </div>

        <section>
          <h2>Article 1 : Objet</h2>
          <p>Les présentes Conditions Générales d'Utilisation (CGU) régissent l'utilisation du quiz d'orientation professionnelle (ci-après "le Service") accessible à l'adresse : www.trouvetonmetier.fr.</p>
          <div className="highlight">
            <p>Le Service propose gratuitement un quiz d'orientation professionnelle utilisant l'IA pour fournir des recommandations personnalisées.</p>
          </div>
        </section>

        <section>
          <h2>Article 2 : Acceptation des CGU</h2>
          <p>L'utilisation du Service implique l'acceptation pleine et entière des présentes CGU.</p>
            <p>Si vous n'acceptez pas ces conditions, vous ne devez pas utiliser le Service.</p>
        </section>

        <section>
          <h2>Article 3 : Description du Service</h2>
          <p>Le Service propose :</p>
          <ul>
            <li>Un quiz d'orientation professionnelle gratuit</li>
            <li>Des recommandations générées par IA</li>
            <li>Une utilisation anonyme, sans création de compte</li>
          </ul>
          <p>Les résultats fournis sont des suggestions à titre indicatif et ne constituent pas des conseils professionnels garantis.</p>
        </section>

        <section>
          <h2>Article 4 : Accès au Service</h2>
          <ul>
            <li>Le Service est accessible gratuitement à tout utilisateur disposant d'un accès Internet</li>
            <li>Aucune création de compte n'est nécessaire</li>
          </ul>
        </section>

        <section>
          <h2>Article 5 : Propriété Intellectuelle</h2>
          <p>Tous les éléments du Service (textes, images, etc.) sont protégés par le droit de la propriété intellectuelle.
            Leur utilisation sans autorisation préalable est interdite.</p>
        </section>

        <section>
          <h2>Article 6 : Limitations de Responsabilité</h2>
          <ul>
            <li>Les recommandations fournies sont générées automatiquement et constituent uniquement des suggestions</li>
            <li>Nous ne garantissons pas l'exactitude ou la pertinence des résultats pour votre situation spécifique</li>
            <li>Nous vous conseillons de consulter des professionnels qualifiés pour toute décision importante concernant votre carrière</li>
            <li>Nous ne sommes pas responsables des décisions prises sur la base des recommandations fournies</li>
          </ul>
        </section>

        <section>
          <h2>Article 7 : Utilisation de l'IA</h2>
          <ul>
            <li>Le Service utilise des algorithmes d'IA pour analyser les réponses et générer des recommandations</li>
            <li>Les résultats peuvent varier selon les réponses fournies</li>
            <li>L'IA est utilisée comme outil d'aide à la décision et non comme substitut à un conseil professionnel</li>
          </ul>
        </section>

        <section>
          <h2>Article 8 : Obligations de l'Utilisateur</h2>
          <p>L'utilisateur s'engage à :</p>
          <ul>
            <li>Utiliser le Service de manière loyale et conforme à sa destination</li>
            <li>Ne pas tenter de perturber le fonctionnement du Service</li>
            <li>Ne pas utiliser le Service à des fins illégales ou malveillantes</li>
          </ul>
        </section>

        <section>
          <h2>Article 9 : Protection des Données</h2>
          <p>Le Service respecte votre vie privée :</p>
          <ul>
            <li>Aucune donnée personnelle n'est collectée</li>
            <li>Les réponses au quiz sont traitées de manière anonyme</li>
            <li>Aucune information n'est conservée après la génération des résultats</li>
          </ul>
        </section>

        <section>
          <h2>Article 10 : Modification des CGU</h2>
          <p>Nous nous réservons le droit de modifier les présentes CGU à tout moment.</p>
            <p>Les utilisateurs seront informés des modifications par la publication des nouvelles CGU sur le site.</p>
        </section>

        <section>
          <h2>Article 11 : Droit Applicable</h2>
          <p>Les présentes CGU sont soumises au droit français.</p>
            <p>Tout litige relatif à leur interprétation et/ou à leur exécution relève des tribunaux français.</p>
        </section>

        <section>
          <h2>Contact</h2>
          <p>Pour toute question concernant ces CGU, vous pouvez nous contacter à :</p>
          <p>contact.ttmweb@gmail.com</p>
        </section>
      </section>
      <button className="btn return" onClick={onBack}>Retour</button>
    </div>
  );
}

export default CGUPage;