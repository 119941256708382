import React, { useState, useEffect } from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import HomePage from './components/HomePage';
import QuestionnairePage from './components/QuestionnairePage';
import ResultsPage from './components/ResultsPage';
import LegalPage from './components/LegalPage';
import CGUPage from './components/CGUPage';
import PrivacyPage from './components/PrivacyPage';
import ContactPage from './components/ContactPage';
import { analyzeAnswers, getJobExplanation } from './services/ttm_ai';
import { questions } from './data/questions';
import { Analytics } from "@vercel/analytics/react"

function App() {
  const [currentPage, setCurrentPage] = useState('home');
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState({});
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      const preloader = document.getElementById('preloader');
      if (preloader) {
        preloader.classList.add('fade-out');
        setTimeout(() => {
          preloader.style.display = 'none';
          setShowContent(true);
        }, 500);
      }
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const startQuestionnaire = () => {
    setCurrentPage('questionnaire');
  };

  const handleAnswer = (answer) => {
    setFadeOut(true);
    setTimeout(() => {
      setAnswers({ ...answers, [currentQuestion]: answer });
      if (currentQuestion < questions.length - 1) {
        setCurrentQuestion(currentQuestion + 1);
      } else {
        showResults();
      }
      setFadeOut(false);
    }, 300);
  };

  const showResults = async () => {
    setCurrentPage('results');
    setIsLoading(true);
    setLoadingProgress(0);
    
    try {
      // Simuler une progression pendant l'analyse
      const progressInterval = setInterval(() => {
        setLoadingProgress(prev => {
          if (prev >= 95) {
            clearInterval(progressInterval);
            return 95;
          }
          return prev + Math.random() * 15;
        });
      }, 500);

      const jobTitles = await analyzeAnswers(answers);
      const jobResults = [];
      for (let i = 0; i < jobTitles.length; i++) {
        const title = jobTitles[i];
        const explanation = await getJobExplanation(title, answers);
        jobResults.push({ title, explanation });
        setResults([...jobResults]);
      }
      
      clearInterval(progressInterval);
      setLoadingProgress(100);
      
      // Petit délai pour montrer 100% avant de cacher le loader
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
      
    } catch (error) {
      console.error("Erreur lors de l'analyse des réponses:", error);
      setResults([{ title: "Erreur", explanation: "Une erreur est survenue lors de l'analyse. Veuillez réessayer plus tard." }]);
      setIsLoading(false);
    }
  };

  const restartQuestionnaire = () => {
    setCurrentQuestion(0);
    setAnswers({});
    setCurrentPage('home');
  };

  const Preloader = () => (
    <div className="loader" role="progressbar" aria-label="Chargement en cours">
      <div className="inner one"></div>
      <div className="inner two"></div>
      <div className="inner three"></div>
    </div>
  );

  const AnalysisLoader = () => (
    <div className="analysis-loader">
      <div className="analysis-content">
        <div className="analysis-spinner"></div>
        <div className="analysis-text">
          <h3>Analyse de vos réponses en cours...</h3>
          <div className="analysis-progress">
            <div 
              className="analysis-progress-bar" 
              style={{ width: `${Math.round(loadingProgress)}%` }}
            ></div>
          </div>
          <div className="analysis-percentage">{Math.round(loadingProgress)}%</div>
        </div>
      </div>
    </div>
  );

  const ProgressBar = () => {
    const progress = ((currentQuestion + 1) / questions.length) * 100;
    /* const remainingQuestions = questions.length - (currentQuestion + 1); */
    return (
      <>
        <div className="progress-container" role="progressbar" aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">
          <div 
            className="progress-bar"
            style={{
              width: `${progress}%`,
              transition: 'width 0.3s ease-in-out'
            }}
          />
          <span className="progress-text">
            Question {currentQuestion + 1} sur {questions.length}
          </span>
        </div>
        <button 
          style={{ 
            marginTop: '10px', 
            fontSize: '0.9rem', 
            color: '#3498DB',
            background: 'none',
            border: '1px solid #3498DB',
            padding: '5px 15px',
            borderRadius: '5px',
            cursor: 'pointer'
          }}
          aria-label={`Question ${currentQuestion + 1} sur ${questions.length}`}
        >
          {currentQuestion + 1} sur {questions.length}
        </button>
      </>
    );
  };

  return (
    <HelmetProvider>
      <div className="app">
        <Helmet>
          <html lang="fr" />
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="theme-color" content="#000000" />
          <meta name="robots" content="index, follow" />
          <link rel="canonical" href="https://trouvetonmetier.fr" />
        </Helmet>

        <div id="preloader" className="preloader" role="status" aria-label="Chargement de l'application">
          <Preloader />
        </div>

        <div className={`content ${showContent ? 'fade-in' : ''}`}>
          {currentPage === 'home' && <HomePage onStart={startQuestionnaire} />}
          {currentPage === 'questionnaire' && (
            <main>
              <ProgressBar />
              <QuestionnairePage
                question={questions[currentQuestion]}
                onAnswer={handleAnswer}
                fadeOut={fadeOut}
              />
            </main>
          )}
          {currentPage === 'results' && (
            <>
              {isLoading ? (
                <AnalysisLoader />
              ) : (
                <ResultsPage
                  results={results}
                  isLoading={isLoading}
                  onRestart={restartQuestionnaire}
                  preloader={<Preloader />}
                />
              )}
            </>
          )}
          {currentPage === 'legal' && <LegalPage onBack={() => setCurrentPage('home')} />}
          {currentPage === 'cgu' && <CGUPage onBack={() => setCurrentPage('home')} />}
          {currentPage === 'privacy' && <PrivacyPage onBack={() => setCurrentPage('home')} />}
          {currentPage === 'contact' && <ContactPage onBack={() => setCurrentPage('home')} />}
        </div>

        <footer>
          <nav aria-label="Liens légaux">
            <a href="/legal" onClick={(e) => { e.preventDefault(); setCurrentPage('legal'); }}>Mentions légales</a>
            <a href="/cgu" onClick={(e) => { e.preventDefault(); setCurrentPage('cgu'); }}>CGU</a>
            <a href="/privacy" onClick={(e) => { e.preventDefault(); setCurrentPage('privacy'); }}>Politique de confidentialité</a>
            <a href="/contact" onClick={(e) => { e.preventDefault(); setCurrentPage('contact'); }}>Contact</a>
          </nav>
          <h6>© 2024 TrouveTonMetier.fr - Tous droits réservés</h6>
        </footer>
      </div>
      <Analytics />
    </HelmetProvider>
    
  );
}

export default App;