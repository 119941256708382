import React from 'react';
import { ReactComponent as RestartIcon } from '../images/restart_btn.svg';

function ResultsPage({ results, isLoading, onRestart, preloader }) {
  if (isLoading) {
    return (
      <div className="page active">
        {preloader}
      </div>
    );
  }

  const parseExplanation = (explanation) => {
    const lines = explanation.split('\n');
    return lines.reduce((acc, line) => {
      const [category, ...contentParts] = line.split(':');
      const trimmedCategory = category.trim();
      if (trimmedCategory && !acc[trimmedCategory]) {
        acc[trimmedCategory] = contentParts.join(':').trim();
      }
      return acc;
    }, {});
  };

  return (
    <div className="page active">
      <h1 className="jobrtitle">Des idées de métier qui vous correspondent !</h1>
      <div id="job-results">
        {results.map((job, index) => {
          const parsedExplanation = parseExplanation(job.explanation);
          return (
            <div key={index} className="job-card">
              <h3>{job.title}</h3>
              <div className="job-details">
                {Object.entries(parsedExplanation).map(([category, content], i) => (
                  <div key={i} className="job-detail">
                    <strong>{category} :</strong> {content}
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
      <button className="restart-btn" onClick={onRestart}>
        Recommencer le quiz<RestartIcon /> 
      </button>
      <button className="more-ideas-btn">
        Plus d'idées ? (+8)
      </button>
      <h4>Ces suggestions de métiers sont basées sur vos réponses actuelles au quiz. Elles sont fournies à titre indicatif et peuvent évoluer dans le temps. Il est recommandé d'approfondir vos recherches et de consulter des professionnels de l'orientation pour affiner votre projet professionnel.</h4>
    </div>
    
  );
}

export default ResultsPage;