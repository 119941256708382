import React from 'react';

function LegalPage({ onBack }) {
  return (
    <div className="page active">
      <h2 className="legalt">Mentions légales</h2>
        <section className="legalp">
          Ce site est édité par Baret Benjamin, Entrepreneur individuel
          <br />
          Siège sociale : 122 rue Amelot, 75011 Paris, France
          <br />
          SIRET : 97994247100017
          <br />
          Téléphone : 07 56 82 06 13.
          <br />
          Contact : contact.ttmweb@gmail.com
          <br />
          Le directeur de la publication est Baret Benjamin.
          <br />
          <br />
          Ce site est hébergé par la société Vercel Inc., 
          <br />
          située 340 S Lemon Ave #4133 Walnut,
          <br /> 
          CA 91789, et joignable au (559) 288-7060.
          <br /><br />
        </section>
        <br />
        <button className="btn return" onClick={onBack}>Retour</button>
      </div>
  );
}

export default LegalPage;