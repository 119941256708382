import React, { useEffect, useState } from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';

function HomePage({ onStart }) {
  const [words, setWords] = useState([]);

  const careerWords = [
    'Photographe', 'Soudeur', 'Motion designer', 'Pilote', 'Architecte', 'Graphiste',
    'Électricien', 'Médecin', 'Avocat', 'Chef cuisinier', 'Psychologue', 'Community Manager',
    'Mécanicien', 'Data Scientist', 'Infirmier', 'Développeur web', 'Comptable', 'Kinésithérapeute',
    'Professeur', 'Journaliste', 'Agriculteur', 'Vétérinaire', 'Artiste', 'Policier',
    'Pompier', 'Ingénieur', 'Musicien', 'Photographe', 'Chercheur', 'Astronome',
    'Biologiste', 'Dentiste', 'Pharmacien', 'Plombier', 'Menuisier', 'Pâtissier'
    ,'Architecte d\'intérieur', 'Urbaniste', 'Ergothérapeute', 'Orthophoniste', 'Podologue',
    'Designer industriel', 'Œnologue', 'Horloger', 'Bijoutier', 'Fleuriste', 'Chocolatier',
    'Danseur', 'Comédien', 'Réalisateur', 'Scénariste', 'Traducteur', 'Interprète',
    'Archéologue', 'Historien', 'Sociologue', 'Anthropologue', 'Guide touristique',
    'Coach sportif', 'Nutritionniste', 'Ostéopathe', 'Sage-femme', 'Opticien',
    'Paysagiste', 'Apiculteur', 'Brasseur', 'Fromager', 'Ébéniste', 'Tapissier'
  ];

  useEffect(() => {
    const createWord = () => {
      const word = careerWords[Math.floor(Math.random() * careerWords.length)];
      const x = Math.random() * 100;
      const y = Math.random() * 100;
      const opacity = Math.random() * 0 + 0.1;
      const scale = Math.random() * 0 + 0.1;
      const duration = Math.random() * 5 + 5;
      
      setWords(prev => [...prev, { word, x, y, opacity, scale, duration, id: Date.now() }]);

      setTimeout(() => {
        setWords(prev => prev.filter(w => w.id !== Date.now()));
      }, duration * 1000);
    };

    const interval = setInterval(createWord, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <HelmetProvider>
      <Helmet>
        <title>Quiz Orientation Professionnelle | Découvre le métier fait pour toi</title>
        <meta name="description" content="Test d'orientation gratuit pour découvrir les métiers qui correspondent à ta personnalité et tes compétences. Quiz rapide pour ton orientation professionnelle." />
        <meta name="keywords" content="orientation professionnelle, test métier, quiz orientation, carrière, choix métier, bilan professionnel" />
        <meta property="og:title" content="Quiz Orientation Professionnelle | Découvre le métier fait pour toi" />
        <meta property="og:description" content="Test d'orientation gratuit pour découvrir les métiers qui correspondent à ta personnalité et tes compétences." />
        <link rel="canonical" href="https://trouvetonmetier.fr/" />
      </Helmet>
      <main className="page active">
        <div className="floating-words">
          {words.map(({ word, x, y, opacity, scale, duration, id }) => (
            <div
              key={id}
              className="floating-word"
              style={{
                left: `${x}%`,
                top: `${y}%`,
                opacity,
                transform: `scale(${scale})`,
                animation: `float ${duration}s linear infinite`
              }}
            >
              {word}
            </div>
          ))}
        </div>
        <h1 id='title'>Quel métier est fait pour toi ?</h1>
        <h2>Un quiz simple et rapide pour découvrir les métiers qui te correspondent.</h2>
        <button className="btn btn-animated" onClick={onStart} aria-label="Commencer le quiz d'orientation professionnelle">
          Lancer le quiz
        </button>
      </main>
      <style>
        {`
          .floating-words {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            z-index: -1;
          }
          
          .floating-word {
            position: absolute;
            color: var(--accent-color);
            font-size: 1.5rem;
            font-weight: 300;
            filter: blur(1px);
            transition: all 0.3s ease;
          }
          
          @keyframes float {
            0% {
              transform: translate(0, 0) scale(1);
              opacity: 0;
            }
            20% {
              opacity: 0.5;
            }
            80% {
              opacity: 0.5;
            }
            100% {
              transform: translate(50px, -100px) scale(0.5);
              opacity: 0;
            }
          }
        `}
      </style>
    </HelmetProvider>
  );
}

export default HomePage;