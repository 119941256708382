import React from 'react';
import { Helmet } from 'react-helmet-async';

function QuestionnairePage({ question, onAnswer, fadeOut }) {
  return (
    <>
      <Helmet>
        <title>Quiz d'orientation professionnelle - Question {question.question}</title>
        <meta name="description" content={`Répondez à la question: ${question.question} - Quiz d'orientation professionnelle gratuit pour découvrir votre futur métier.`} />
        <link rel="canonical" href="https://trouvetonmetier.fr/" />
      </Helmet>

      <div className={`page questionnaire ${fadeOut ? 'fade-out' : 'fade-in'}`}>
        <h2 id="question" aria-live="polite">{question.question}</h2>
        <div id="options" role="group" aria-label="Options de réponse">
          {question.options.map((option, index) => (
            <button 
              key={index}
              className="btn_answer btn-animated"
              onClick={() => onAnswer(option)}
              aria-label={`Répondre: ${option}`}
            >
              {option}
            </button>
          ))}
        </div>
      </div>
    </>
  );
}

export default QuestionnairePage;